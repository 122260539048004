<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Magazines </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link :to="{name:'magazines'}">Magazines</router-link></li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8" v-for="Data in RespArray" v-bind:key="Data.id">
                <div>
                  <b-row class="page-title-text" >
                    <h5>{{Data.magazine_name}}</h5>
                  </b-row>    
                </div>
                <div v-for="Data in RespArray" v-bind:key="Data.id">
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-12 Thira profile-card-5">
                        <div class="card-img-block">
                          <img v-bind:src="Data.magazine_photo" alt="Image">
                        </div>
                        <p class="pre-formatted" v-if='Data.magazine_description !== ("" || null)' ><span v-html="Data.magazine_description"></span></p>    
                    </div>
                  </b-row>    
                </div>   
                <div class="profile-card">
                  <GoogleAdsense slot="2761789315"/>
                </div>              
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar search box -->
                <!-- <h3 class="sidebar-title">Search</h3>
                <div class="sidebar-item search-form">
                  <form action="">
                    <input type="text">
                    <button type="submit"><i class="icofont-search"></i></button>
                  </form>
                </div> -->
                <!-- sidebar Categories -->
                <!-- <h3 class="sidebar-title">Categories</h3>
                <div class="sidebar-item categories">
                  <ul>
                    <li><a href="#">Dance <span>(25)</span></a></li>
                    <li><a href="#">Music <span>(12)</span></a></li>
                    <li><a href="#">Drama <span>(5)</span></a></li>
                    <li><a href="#">Folk Arts <span>(22)</span></a></li>
                    <li><a href="#">Instruments <span>(8)</span></a></li>
                    <li><a href="#">Kshetra Vadyam <span>(14)</span></a></li>
                    <li><a href="#">Performing Arts <span>(14)</span></a></li>
                    <li><a href="#">Ritual Arts <span>(14)</span></a></li>
                  </ul>
                </div>  -->
                <!-- <Side Recent Performers/> -->
                <SideRecentPerformers/>  
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"Magazines",
    //insert the following code for vue-meta to work
    metaInfo() {
        return {
            title: `${this.MetaName} - Art Magazines | Online Program Booking | Artist Portfolios | Book Stage Shows :: Thiraseela.com`,
            meta: [
                { name: 'description', content: this.MetaName + '-' + ' - Art Magazines | Online Program Booking | Artist Portfolios | Book Stage Shows :: Thiraseela.com'},
                { property: 'og:title', content: this.MetaName + ' - ' + ' - Art Magazines | Online Program Booking | Artist Portfolios | Book Stage Shows :: Thiraseela.com'},
                { property: 'og:site_name', content: 'Thiraseela.com'},
                { property: 'og:description', content: 'Thiraseela.com Art Magazines :: ' + this.MetaName + '-'},
                {property: 'og:type', content: 'profile'},
                // {property: 'og:url', content: 'https://thiraseela.com/troupes-detail/' + this.MetaSeqno + '/' + this.MetaName},
                {property: 'og:image', content: this.MetaUrl }    
            ]
        }
    },         
    data() {
      return {
        RespArray: [],
        MetaName: '',
        MetaTagline: '',
        MetaSeqno: '',
        MetaUrl: '',          
      }
    },
    mounted()
    {
      this.ReadDtlMagazines(this.$route.params.MagazineId)
    },
    methods: {
      ReadDtlMagazines(RecToFetch){  
        const REQformData = new FormData();
        REQformData.append('seq_no', RecToFetch);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadDtlMagazines',REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.DtlMagazines;
        const FirstObject = resp.data.DtlMagazines[0];
        this.MetaName = FirstObject.magazine_name;
        // this.MetaTagline = FirstObject.troupe_pgm_name;
        this.MetaSeqno = FirstObject.seq_no;
        this.MetaUrl = FirstObject.magazine_photo;          
      })
      },
    },
    computed: { 
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: auto;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    float: center;
    width: 300px;
    padding: 10px 10px 10px 10px;
    height: auto;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: auto;
  /* overflow: hidden; */

}
.profile-card-5 .card-img-block img{
    float: left;
    width: 300px;
    margin-top:5px;
    padding: 10px 20px 20px 10px;
    max-height: 400px;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify;
}
.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}
</style>
